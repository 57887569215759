import React from 'react';
import { Field } from 'formik';
import styled from 'styled-components';

const RadioButtonWrapper = styled.div`
  position: relative;
`;

const Label = styled.label`
  display: inline-block;
  cursor: pointer;
  padding: 10px 7px 0 28px;
  ${({ fullWidthLabel }) => fullWidthLabel && 'width: 100%;'}
  &::before,
  &::after {
    content: ' ';
    border: 1px solid;
    height: 1.125em;
    left: 0.125em;
    position: absolute;
    top: 0.825em;
    width: 1.125em;
    border-radius: 100%;
    transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  }

  &::after {
    border-color: #565656;
  }

  &::before {
    border-color: transparent;
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0);
  }
`;

const Input = styled.input`
  position: absolute;
  height: 0;
  width: 0;
  background: none;
  opacity: 0.00001;
  z-index: 2;

  &:checked ~ ${Label}::before {
    border-color: transparent;
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.primaryBlue};
  }

  &:checked ~ ${Label}::after {
    border-color: #fff;
    border-width: 3px;
    box-shadow: inset 0 0 0 8px ${({ theme }) => theme.colors.primaryBlue};
  }

  &:focus ~ ${Label} ::before {
    border-color: transparent;
    box-shadow: 0 0 0 4px #228bec;
  }

  &[disabled] ~ ${Label} {
    opacity: 0.5;
  }
`;

const getValidator = required => (value) => {
  if (required && !value) {
    return 'Required';
  }

  return undefined;
};

export default function RadioButtonField(props) {
  const { children, label, fullWidthLabel, defaultValue = '', ...fieldProps } = props;

  return (
    <Field
      name={props.name}
      validate={getValidator(props.required)}
      render={({ field: { value, ...field } }) => {
        const isChecked = !value ? fieldProps.value === defaultValue : value === fieldProps.value;

        const input = <Input {...field} type="radio" checked={isChecked} {...fieldProps} />;

        return (
          <RadioButtonWrapper>
            {input}
            <Label htmlFor={props.id} fullWidthLabel={fullWidthLabel}>{children || label}</Label>
          </RadioButtonWrapper>
        );
      }}
    />
  );
}
