const getPageYOffset = () => {
  if (window.pageYOffset) {
    return window.pageYOffset;
  }

  return document.documentElement.clientHeight
    ? document.documentElement.scrollTop
    : document.body.scrollTop;
};

const scrollToTop = () => {
  try {
    const pageYOffset = getPageYOffset();

    // If top of element is above the top of the viewport, scroll the viewport
    if (pageYOffset > 0) {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn(e);
  }
};

export default scrollToTop;
