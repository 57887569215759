import React from 'react';
import styled from 'styled-components';

import CheckboxField from 'form-fields/styled/CheckboxField';
import { CheckboxWrapperStyled, CheckboxInputStyles, CheckboxLabel } from './checkboxStyles';

const CheckboxInput = styled(CheckboxField)`
  ${CheckboxInputStyles}
`;

function StyledCheckbox({ children, name, className, isLarge, disabled, ...rest }) {
  const id = `${name}_checkbox`;

  return (
    <CheckboxWrapperStyled className={className} isLarge={isLarge}>
      <CheckboxInput
        type="checkbox"
        name={name}
        id={id}
        isLarge={isLarge}
        disabled={disabled}
        {...rest}
      />
      <CheckboxLabel htmlFor={id} isLarge={isLarge} disabled={disabled}>
        {children}
      </CheckboxLabel>
    </CheckboxWrapperStyled>
  );
}

export default StyledCheckbox;
