import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'formik';
import isEqual from 'is-equal';

class SideEffect extends React.Component {
  componentWillReceiveProps(nextProps) {
    const { formik } = this.props;
    const { formik: nextFormik } = nextProps;

    if (nextFormik.values !== formik.values) {
      if (!isEqual(formik.values, nextFormik.values)) {
        const currentState = {
          values: formik.values,
          touched: formik.touched,
          errors: formik.errors,
          isSubmitting: formik.isSubmitting,
        };

        const nextState = {
          values: nextFormik.values,
          touched: nextFormik.touched,
          errors: nextFormik.errors,
          isSubmitting: nextFormik.isSubmitting,
        };

        const formikMethods = nextFormik;

        this.props.onChange(currentState, nextState, formikMethods);
      }
    }
  }

  render() {
    return null;
  }
}

SideEffect.propTypes = {
  formik: PropTypes.shape({}).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default connect(SideEffect);
